// types
import type {
  Enrollment,
  Student,
  Subject,
  Tutor,
  TutoringHistory
} from '@revolutionprep/types'

// nuxt
import { useNuxtApp } from '#app'

// stores
import { useScheduleSessionsV2Store } from '@/store/schedule-sessions-v2'
import { useTrialStore } from '@/store/trial'

export function useScheduleSessionsV2 () {
  /**
   * nuxt app
   * ==================================================================
   */
  const { $actor, $growthbook } = useNuxtApp()

  /**
   * route & router
   * ==================================================================
   */
  const route = useRoute()
  const router = useRouter()

  /**
   * composables
   * ==================================================================
   */
  const { getUnscheduledHours } = useUnscheduledHours()

  /**
   * stores
   * ==================================================================
   */
  const enrollmentStore = useEnrollmentStore()
  const { enrollment } = storeToRefs(enrollmentStore)

  const trialStore = useTrialStore()
  const { isExpiredTrial, trialEnrollment } = storeToRefs(trialStore)

  const organizationStore = useOrganizationStore()
  const { organizationInfo: company } = storeToRefs(organizationStore)

  const scheduleSessionsV2Store = useScheduleSessionsV2Store()
  const {
    sessionSubject,
    sessionSubjectId,
    sessionDuration,
    sessionFocus,
    sessionFrequency
  } = storeToRefs(scheduleSessionsV2Store)

  /**
   * state
   * ==================================================================
   */
  const enrollmentShowIncludes = ref(
    'active_affiliate_reservations.tutor_package,available_tiers,brand,course.brand.subjects,course.subject,suggested_subject'
  )

  /**
   * computed
   * ==================================================================
   */
  // actor
  const actor = computed(() => {
    return $actor.core.actor.value as Student
  })

  const actorId = computed(() => {
    return $actor.core.actorId.value
  })

  // auth
  const isLoggedIn = computed(() => {
    return Boolean($actor.core.isLoggedIn.value && actor.value)
  })

  // brand
  const brandId = computed(() => {
    return enrollment.value?.brand?.id
  })

  const brandName = computed(() => {
    return enrollment.value?.brand?.name
  })

  // enrollment
  const enrollmentId = computed(() => {
    return Number(route.params.enrollmentId)
  })

  const tutoringEnrollments = computed(() => {
    const enrollments = enrollmentStore.getTutoringEnrollments()

    if (isExpiredTrial.value) {
      const trialIndex = enrollments.findIndex(
        enrollment => enrollment?.brand?.name === 'Trial'
      )

      if (trialIndex !== -1) {
        trialEnrollment.value = enrollments[trialIndex]
        enrollments.splice(trialIndex, 1)
      }
    }

    return enrollments
  })

  const unscheduledHours = computed(() => {
    return getUnscheduledHours(enrollment.value)
  })

  // tutors
  const tutorCount = computed(() => {
    return tutoringEnrollments.value?.reduce((
      count: number,
      enrollment: Enrollment
    ) => {
      count += getEnrollmentTutors(enrollment).length
      return count
    }, 0)
  })

  const tutorCountLabel = computed(() => {
    return tutorCount.value === 1 ? 'tutor' : 'tutors'
  })

  // schedule sessions steps
  const currentStepIndex = computed(() => {
    const index = steps.value.findIndex(step => step.routePath === route.path)
    return index > -1 ? index : 0
  })

  const scheduleSessionsStep = computed(() => {
    return currentStepIndex.value + 1
  })

  const differencesEnabled = computed(() => {
    return $growthbook?.isOn('learning-differences') || false
  })

  const sessionFocusEnabled = computed(() => {
    return $growthbook?.isOn('first-sessions-focus') || false
  })

  const shouldIncludeGoalsStep = computed(() => {
    return differencesEnabled.value || sessionFocusEnabled.value
  })

  const steps = computed(() => {
    const baseSteps = [
      {
        routePath: `/schedule-sessions/${enrollmentId.value}/subject`,
        stepTitle: 'Subject'
      },
      shouldIncludeGoalsStep.value && {
        routePath: `/schedule-sessions/${enrollmentId.value}/goals`,
        stepTitle: 'Goals'
      },
      {
        routePath: `/schedule-sessions/${enrollmentId.value}/availability`,
        stepTitle: 'Availability'
      },
      {
        routePath: `/schedule-sessions/${enrollmentId.value}/confirm`,
        stepTitle: 'Schedule'
      }
    ].filter(Boolean)

    return baseSteps.map((step, index) => ({
      ...step,
      stepNumber: index + 1
    }))
  })

  // timezone
  const timezone = computed(() => {
    return new Date().toLocaleString(
      'en',
      { timeZoneName: 'short' }
    ).split(' ').pop()
  })

  // session
  const sessionSubjectName = computed(() => {
    return storedSessionSubject.value?.name || ''
  })

  /**
   * methods
   * ==================================================================
   */
  // enrollments
  function getEnrollmentTutors (enrollment: Enrollment) {
    return enrollment?.tutoringHistories?.reduce((
      tutorArray: Partial<Tutor>[],
      tutoringHistory: TutoringHistory
    ) => {
      if (tutoringHistory.tutor) {
        tutorArray.push(tutoringHistory.tutor)
      }
      return tutorArray
    }, []) || []
  }

  function toNextStep () {
    return router.replace({
      path: steps.value[currentStepIndex.value + 1].routePath
    })
  }

  function toPrevStep () {
    const step = currentStepIndex.value - 1

    if (step < 0) {
      return router.replace({
        path: '/schedule-sessions'
      })
    }

    return router.replace({
      path: steps.value[step].routePath
    })
  }

  /**
   * storage
   * ==================================================================
   */
  const storedSessionDuration = computed(() => {
    return $actor.core.storage.getUniversal<number>(
      `sessionDuration-${actorId.value}`
    )
  })

  const storedSessionFrequency = computed(() => {
    return $actor.core.storage.getUniversal<number>(
      `sessionFrequency-${actorId.value}`
    )
  })

  const storedSessionFocus = computed(() => {
    return $actor.core.storage.getUniversal<string>(
      `sessionFocus-${actorId.value}`
    )
  })

  const storedSessionSubject = computed(() => {
    return $actor.core.storage.getUniversal<Subject>(
      `sessionSubject-${actorId.value}`
    )
  })

  const storedSessionSubjectId = computed(() => {
    return $actor.core.storage.getUniversal<number>(
      `sessionSubjectId-${actorId.value}`
    )
  })

  function clearScheduleLocalStorage () {
    $actor.core.storage.setUniversal(
      `sessionSubject-${actorId.value}`,
      undefined
    )
    $actor.core.storage.setUniversal(
      `sessionSubjectId-${actorId.value}`,
      undefined
    )
    $actor.core.storage.setUniversal(
      `sessionDuration-${actorId.value}`,
      undefined
    )
    $actor.core.storage.setUniversal(
      `sessionFrequency-${actorId.value}`,
      undefined
    )
    $actor.core.storage.setUniversal(
      `sessionFocus-${actorId.value}`,
      undefined
    )
  }

  return {
    actor,
    actorId,
    brandId,
    brandName,
    clearScheduleLocalStorage,
    company,
    enrollment,
    enrollmentId,
    enrollmentShowIncludes,
    getEnrollmentTutors,
    isLoggedIn,
    scheduleSessionsStep,
    sessionDuration,
    sessionFrequency,
    sessionFocus,
    sessionSubject,
    sessionSubjectId,
    sessionSubjectName,
    steps,
    storedSessionDuration,
    storedSessionFocus,
    storedSessionFrequency,
    storedSessionSubject,
    storedSessionSubjectId,
    timezone,
    toNextStep,
    toPrevStep,
    tutorCount,
    tutorCountLabel,
    tutoringEnrollments,
    unscheduledHours
  }
}
